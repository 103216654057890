<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="article, actions"
    ></v-skeleton-loader>
    <SettingsBusinessInfoCardVue
      v-else
      :company-infos="settings.company"
      @companyUpdate="companyUpdate"
    ></SettingsBusinessInfoCardVue>
    <v-skeleton-loader
      v-if="loading"
      type="article, actions"
    ></v-skeleton-loader>
    <div v-else>
      <SettingsBusinessVisualInfosCardVue
        :company-visuals="settings.companyVisuals"
        @companyUpdate="companyUpdate"
      ></SettingsBusinessVisualInfosCardVue>
      <SettingsBusinessEmailSignatureCardVue
        :company-email-signature="settings.emailSignature"
        :website="settings.company.website"
        @companyUpdate="companyUpdate"
      ></SettingsBusinessEmailSignatureCardVue>
    </div>
  </div>
</template>

<script>
import SettingsBusinessInfoCardVue from './SettingsBusinessInfoCard.vue'
import SettingsBusinessVisualInfosCardVue from './SettingsBusinessVisualInfosCard.vue'
import SettingsBusinessEmailSignatureCardVue from './SettingsBusinessEmailSignatureCard.vue'

export default {
  components: {
    SettingsBusinessInfoCardVue,
    SettingsBusinessVisualInfosCardVue,
    SettingsBusinessEmailSignatureCardVue,
  },
  data() {
    return {
      loading: true,
      settings: {},
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchSettings()])
        .then(values => {
          [this.settings] = values
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchSettings() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/options`)

      return response.data
    },
    companyUpdate(payload) {
      this.settings = payload
    },
  },
}
</script>

<style>

</style>
