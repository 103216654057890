<template>
  <v-card class="overflow-hidden my-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Apparence de l'entreprise
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiBrush }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="name">Nom d'affichage</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="displayName"
                  v-model="companyVis.displayName"
                  outlined
                  dense
                  placeholder="Nom d'affichage de l'entreprise"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn
                class="mx-2"
                outlined
                @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                :disabled="!hasChanged"
                color="primary"
                @click="updateCompany"
              >
                Mettre à jour
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiChevronUp, mdiChevronDown, mdiBrush,
} from '@mdi/js'

export default {
  props: {
    companyVisuals: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      companyVis: {
        displayName: '',
        logo: {},
      },
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiBrush,
      },
    }
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.companyVis) !== JSON.stringify(this.companyVisuals)
    },
  },
  watch: {

  },
  mounted() {
    this.companyVis = { ...this.companyVisuals }
  },
  methods: {
    reset() {
      this.companyVis = { ...this.companyVisuals }
    },
    updateCompany() {
      this.$http.put(`${process.env.VUE_APP_API_URL}/options`, {
        companyVisuals: this.companyVis,
      })
        .then(res => {
          this.$emit('companyUpdate', res.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Entreprise mise à jour',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur',
            value: true,
          })
        })
        .finally(
        )
    },
    updatedSignature(e) {
      console.log(e)
    },
  },
}
</script>

<style>
</style>
